import { reduce } from 'lodash/fp'

export default ({ fields }) => ({
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    validation() {
      return reduce(
        (result, field) => ({
          ...result,
          [field]:
            this.$v.form[field].$dirty && this.$v.form[field].$invalid
              ? false
              : null
        }),
        {},
        fields
      )
    }
  },
  methods: {
    handleSubmit() {
      this.$v.form.$touch()
      !this.$v.form.$invalid && this.$emit('submit', this.form)
    },
    async handleCancel(name) {
      const result = await this.$bvModal.msgBoxConfirm(
        'Deseja realmente sair? Alterações não salvas serão perdidas.'
      )

      if (result) {
        this.$router.push({ name })
      }
    }
  }
})

<template>
  <b-form novalidate @submit.prevent="handleSubmit">
    <b-form-group label="E-mail" label-for="email">
      <b-form-input
        id="email"
        v-model="form.email"
        type="email"
        required
        :state="validation.email"
      />
      <b-form-invalid-feedback v-if="$v.form.email.$invalid">
        Informe um e-mail válido
      </b-form-invalid-feedback>
    </b-form-group>
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <b-button variant="success" type="submit">
        <span class="fas fa-envelope" /> Redefinir Senha
      </b-button>
      <router-link :to="{ name: 'Login' }" class="text-decoration-none">
        <span class="fas fa-sign-in-alt mx-1" />Login
      </router-link>
    </div>
  </b-form>
</template>

<script>
import withFormValidation from '@/mixins/withFormValidation'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'ResetPasswordForm',
  mixins: [
    withFormValidation({
      fields: ['email']
    })
  ],
  data() {
    return {
      form: {
        email: ''
      }
    }
  },
  validations: {
    form: {
      email: { required, email }
    }
  },
  watch: {
    form(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<template>
  <b-container class="mt-5 w-50">
    <ResetPasswordForm v-model="credentials" @submit="onSubmit" />
  </b-container>
</template>

<script>
import ResetPasswordForm from '@/components/Auth/ResetPasswordForm'

export default {
  name: 'ResetPassword',
  components: {
    ResetPasswordForm
  },
  data() {
    return {
      credentials: {
        email: ''
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('ToDo')
    }
  }
}
</script>
